import { frontPageConstants }                   from '../../../../../server/routers/pageRouters/frontPageRouters/tiaoMa/base';
import { miscApiConstants, MiscApiInterfaces }  from '../../../../../server/routers/apiRouters/miscRouters/base';

declare const tiaoMaMessageForm;

class TiaoMaMessage {
    messageModal:   any     = null;
    verifyCodeID:   string  = '';

    pageLoaded() {
        
    }

    showMessageModal() {
        this.queryVerifyCode();
        
        if (this.messageModal) {
            this.messageModal.modal('show');
        } else {
            this.messageModal = $('#message-modal')
                .on('hidden.bs.modal', () => {
                    tiaoMaMessageForm.reset();
                    $('[name=tiaoMaMessageForm]').removeClass('was-validated');
                    const blockEle: HTMLElement = $('#message-modal .modal-body');
                    commonFuns.unblockUI(blockEle);
                }).modal('show');
        }
    }

    async queryVerifyCode() {
        const result: MiscApiInterfaces.GetVerifyCodeRes = await httpFuns.get(miscApiConstants.apiVerifyCode, {});

        if (result) {
            this.verifyCodeID = result.verifyCodeID;
            $('#verifyCodePng').show().attr('src', result.pngData);
        }
    }

    async saveMessage() {
        const checkFormOK: boolean = commonFuns.formValadition('tiaoMaMessageForm');
        if (!checkFormOK) {
            return;
        }

        const message       = tiaoMaMessageForm.message.value.trim();
        const contact       = tiaoMaMessageForm.contact.value.trim();
        const verifyCode    = tiaoMaMessageForm.verifyCode.value.trim();

        if (!message) {
            commonFuns.showAlert('留言内容不能为空', 'danger');
            return;
        }

        if (message.length > 1000) {
            commonFuns.showAlert('留言内容不能超过1000个字符', 'danger');
            return;
        }

        if (!contact) {
            commonFuns.showAlert('联系方式不能为空', 'danger');
            return;
        }

        if (!commonFuns.isEmailValid(contact) && !commonFuns.isMobileNumber(contact)) {
            commonFuns.showAlert('联系方式格式不对，应为手机号或邮箱', 'danger');
            return;
        }

        if (!verifyCode) {
            commonFuns.showAlert('验证码不能为空', 'danger');
            return;
        }

        if (verifyCode.length !== 4) {
            commonFuns.showAlert('验证码长度为4', 'danger');
            return;
        }

        const blockEle: HTMLElement = $('#message-modal .modal-body');
        commonFuns.blockUI(blockEle, '正在提交留言...');
        
        const requestParams: MiscApiInterfaces.PostMessageReq = {
            message,
            contact,
            verifyCodeID:   this.verifyCodeID,
            verifyCode:     verifyCode,
        };

        const result: MiscApiInterfaces.PostMessageReq = await httpFuns.post(miscApiConstants.apiMessage, requestParams);
        if (result) {
            location.href = `${location.protocol}//${location.hostname}${location.pathname}`;
            this.messageModal.modal('hide');
        }

        commonFuns.unblockUI(blockEle);
    }
}

const tiaoMaMessage            = new TiaoMaMessage();
(window as any).tiaoMaMessage  = tiaoMaMessage;


$(document).ready(() => {
    if (location.pathname === frontPageConstants.pageMessage.routerPath) {
        tiaoMaMessage.pageLoaded();
    }
});