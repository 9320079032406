import { miscApiConstants, MiscApiInterfaces }  from '../../../../../server/routers/apiRouters/miscRouters/base';
import { CommonInterfaces }                     from '../../../../../server/interfaces';

class TiaoMaLayout {
    async download() {
        const params: MiscApiInterfaces.GetDownloadReq = {
            appID:  CommonInterfaces.ProductAppID.tiaoMa,
        };

        const result: MiscApiInterfaces.GetDownloadRes = await httpFuns.get(miscApiConstants.apiDownload, params);
        if (result) {
            $('#a-download').attr('href', result.downloadUrl)[0].click();
        }
    }
}

const tiaoMaLayout              = new TiaoMaLayout();
(window as any).tiaoMaLayout    = tiaoMaLayout;

$(document).ready(() => {});