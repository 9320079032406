import { CommonInterfaces } from '../../../../../interfaces';

const frontPageConstants: CommonInterfaces.FrontPageConstants = {
    pageHome: {
        routerName:     '首页',
        routerPath:     '/',
        routerIcon:     '<i class="ri-home-3-line"></i>',
        templatePath:   '/frontPages/tiaoMa/home/index.ejs',
    },
    pageIndex: {
        routerName:     '首页',
        routerPath:     '/index.html',
        routerIcon:     '<i class="ri-home-3-line"></i>',
        templatePath:   '/frontPages/tiaoMa/home/index.ejs',
    },
    pageDownload: {
        routerName:     '下载',
        routerPath:     '/download.html',
        routerIcon:     '<i class="ri-download-line"></i>',
        templatePath:   '/frontPages/tiaoMa/download/index.ejs',
    },
    pageBuy: {
        routerName:     '购买',
        routerPath:     '/buy.html',
        routerIcon:     '<i class="ri-shopping-bag-3-line"></i>',
        templatePath:   '/frontPages/tiaoMa/buy/index.ejs',
    },
    pageNews: {
        routerName:     '资讯',
        routerPath:     `/news.html`,
        routerIcon:     '<i class="ri-file-copy-2-line"></i>',
        templatePath:   '/frontPages/tiaoMa/news/index.ejs',
    },
    pageNewsDetail: {
        routerName:     '资讯',
        routerPath:     `/news/:newsID.html`,
        routerIcon:     '<i class="ri-article-line"></i>',
        templatePath:   '/frontPages/tiaoMa/news/index.ejs',
    },
    pageMessage: {
        routerName:     '留言',
        routerPath:     `/message.html`,
        routerIcon:     '<i class="ri-message-2-line"></i>',
        templatePath:   '/frontPages/tiaoMa/message/index.ejs',
    },
    pageAbout: {
        routerName:     '关于',
        routerPath:     `/about.html`,
        routerIcon:     '<i class="ri-user-3-line"></i>',
        templatePath:   '/frontPages/tiaoMa/about/index.ejs',
    },
    page404: {
        routerName:     '404',
        routerPath:     '/404.html',
        routerIcon:     '',
        templatePath:   '/frontPages/tiaoMa/404/index.ejs',
    },
    pageHelp: {
        routerName:     '帮助',
        routerPath:     '/help.html',
        routerIcon:     '<i class="ri-booklet-line"></i>',
        templatePath:   '/frontPages/help/index.ejs',
    },
    pageHelpContent: {
        routerName:     '帮助',
        routerPath:     `/help/:helpCode.html`,
        routerIcon:     '<i class="ri-booklet-line"></i>',
        templatePath:   '/frontPages/help/index.ejs',
    },
};

export {
    frontPageConstants,
};