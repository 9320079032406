export interface DBConfigs {
    mongoDBConfigs: MongoDBConfigs;
}

export interface MongoDBConfigs {
    host:           string;
    port:           string;
    database:       string;
    tablePrefix:    string;
    user:           string;
    password:       string;
}

export interface FolderPaths {
    baseUploadPath:     string;

    zipTmpFilesPath:    string;
}

export enum PagePrefix {
    admin           = '/admin',
    tiaoMa          = '/tiaoMa',
    zhengJianZhao   = '/zhengJianZhao',
    paiKe           = '/paiKe',
}

export enum ProductAppID {
    tiaoMa          = '10014',
    paiKe           = '10069',
    fenBan          = '10068',
    zhengJianZhao   = '10009',
}

export interface DataPageQuery {
    page:   number;
    length: number;
}

export interface FrontPageRouter {
    routerPath:     string;
    routerName:     string;
    templatePath:   string;
    routerIcon:     string;
}
export interface FrontPageConstants {
    [x: string]: FrontPageRouter;
}