import { frontPageConstants }   from '../../../../../server/routers/pageRouters/frontPageRouters/tiaoMa/base';

class TiaoMaHome {
    videoModal:         any     = null;
    playingVideoSrc:    string  = '';

    pageLoaded() {
        $(window).scroll(this.initScroll);
        this.initScroll();
        this.init();
    }

    initScroll() {
        // const scroll = $(window).scrollTop();

        // if (scroll > 600) {
        //     $('.features-3.ease-out').css({ opacity: 1, transform: 'translateY(50px) translateY(-50px)' })
        // } else {
        //     $('.features-3.ease-out').css({ opacity: 0, transform: 'translateY(50px)' });
        // }

        // if (scroll > 1050) {
        //     $('.features-4.ease-out').css({ opacity: 1, transform: 'translateY(50px) translateY(-50px)' })
        // } else {
        //     $('.features-4.ease-out').css({ opacity: 0, transform: 'translateY(50px)' });
        // }

        // if (scroll > 1900) {
        //     $('.features-5.ease-out').css({ opacity: 1, transform: 'translateY(50px) translateY(-50px)' })
        // } else {
        //     $('.features-5.ease-out').css({ opacity: 0, transform: 'translateY(50px)' });
        // }

        // if (scroll > 2400) {
        //     $('.features-6.ease-out').css({ opacity: 1, transform: 'translateY(50px) translateY(-50px)' })
        // } else {
        //     $('.features-6.ease-out').css({ opacity: 0, transform: 'translateY(50px)' });
        // }
    }

    init() {
        $('.features-2 .tabs ul li a').hover((ele) => {
            const href = $(ele.target).attr('href');
            if (href) {
                const id = href.replace('#', '');
                $('.features-2 .tab-pane').hide();
                $(`.features-2 .tab-pane#${id}`).show();
            }
            $(ele.target).tab('show');
        });

        $('.features-4 ul li').hover((ele) => {
            $('.features-4 ul li').removeClass('active');
            $(ele.target).addClass('active');
            let yingyongIndex = ele.target.id.replace('yingyong-', '');
            if (!yingyongIndex) {
                yingyongIndex = 1;
                $('.features-4 ul li#yingyong-1').addClass('active');
            }
            $('.features-4 .left .img').removeClass('active');
            $(`.features-4 .left .yingyong-img-${yingyongIndex}`).addClass('active');
        });
    }

    showVideoModal(videoSrc: string) {
        if (this.playingVideoSrc !== videoSrc) {
            this.playingVideoSrc = videoSrc;
            $('#home-video').attr('src', videoSrc);
        }

        if (this.videoModal) {
            this.videoModal.modal('show');
        } else {
            this.videoModal = $('#home-video-modal').modal('show');
        }
    }
}

const tiaoMaHome            = new TiaoMaHome();
(window as any).tiaoMaHome  = tiaoMaHome;


$(document).ready(() => {
    if (location.pathname === frontPageConstants.pageHome.routerPath) {
        tiaoMaHome.pageLoaded();
    }
});